<template>
  <div>

    <div class="container relative z-10">
      <div class="flex flex-col mt-16 lg:flex-row-reverse lg:mt-32">
        <div class="lg:w-1/2 text-center mt-8 lg:mt-0">
          <div class="image-shadow shadow-lightest top-right">
            <image-transition :intervalTimer="4000"
                              :feed="imageTransitions.main"></image-transition>
          </div>
        </div>

        <div class="text px-16 lg:w-1/2 lg:px-24">
          <h2>Dóm Złote Pola</h2>
          <p>
            Dóm Złote Pola znajduje się w bezpośrednim sąsiedztwie Trójmiasta, przy ul. Złote Pola
            w urokliwym Miszewie, obok Banina. To doskonała lokalizacja, do której łatwo dojechać
            z Trójmiasta i z Kaszub. Czyste powietrze i sielska atmosfera są idealnym otoczeniem
            dla wyjątkowych okazji rodzinnych jak i spotkań firmowych. Dóm Złote Pola położony jest wśród
            łąk i ziemi uprawnych. W bezpośrednim sąsiedztwie znajduje się ekologiczne gospodarstwo. Do Domu Złote Pola
            należy
            uroczy ogród z warzywami, kwiatami i owocami.
            To tutaj, w otoczeniu natury znajdziecie Państwo miejsce, w którym zorganizujemy dla Was
            rodzinne chrzciny, wyjątkowe urodziny, przyjęcie komunijne, romantyczne wesele.
            Nasz Dóm otwieramy również na stylowe firmowe spotkania opłatkowe, Mikołajki czy bankiety.<br>
            <br>
            Do Państwa dyspozycji oddajemy piękną, pełną światła salę na przyjęcia, a także przygotowaną
            specjalnie dla dzieci bawialnię BabyCafe.pl. Do okien Domu Złote Pola zagląda zielony ogród,
            który daje wytchnienie i koi ciszą, kiedy macie ochotę na zaczerpnięcie świeżego powietrza.<br>
          </p>
        </div>
      </div>
    </div>
    <div v-rellax="{speed: 5, center: true}" class="leaf"></div>
    <div class="container relative z-10 lg:px-16">
      <div class="flex flex-col mt-16 lg:flex-row lg:mt-32">
        <div class="lg:w-1/2 text-center">
          <div class="image-shadow shadow-lightest top-left">
            <img src="@/assets/images/dom-zlote-pola/tort.jpg" alt="" class="">
          </div>
        </div>
        <div class="text px-16 lg:w-1/2 lg:px-24">
          <h2>Co nas wyróżnia?</h2>
          <p>
            Państwa dzieci są u nas bardzo mile widziane, to o ich dobrze spędzony czas zadbaliśmy
            szczególnie. Bawialnia dziecięca zapewnia rozrywkę i zabawę najmłodszym gościom
            w czasie przyjęć, a wygodne leżaczki i materace pozwolą odpocząć zmęczonym nóżkom.
            Zapewniamy Państwu nocleg – Dóm Złote Pola dysponuje komfortowymi pokojami dla
            gości.
            Otwieramy przed Wami drzwi do niezwykłego domu. Wypełnijcie go swoją radością, niezapomnianymi
            chwilami, wzruszeniami, a także beztroską zabawą. Niech Dóm Złote Pola będzie domem dla całej
            Waszej rodziny. Zapewniamy gościnność, rodzinną atmosferę, zapachy i smaki domowych potraw z
            przepisów Mamy.
          </p>
          <br>
          <p><strong>Nasze atuty</strong></p><br>
          <ul>
            <li>domowe jedzenie, rodzinne przepisy, własne uprawy,</li>
            <li>bawialnia dla dzieci, plac zabaw w ogrodzie,</li>
            <li>ponad 10 letnie doświadczenie,</li>
            <li>gościnność, dbałość o szczegóły,</li>
            <li>otoczenie pól, świeże powietrze, przestrzeń,</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageTransition from "@/components/ImageTransition";

export default {
  components: {
    ImageTransition
  },
  metaInfo: {
    title: 'Dóm Złote Pola',
    titleTemplate: '%s - O nas',
    htmlAttrs: {
      lang: 'pl',
      amp: true
    }
  },
  data: () => ({
    imageTransitions: {
      'main': [
        require('@/assets/images/dom-zlote-pola/n2.jpg'), // 2 = 1
        require('@/assets/images/dom-zlote-pola/ori1.jpg'), // 4 = 2
        require('@/assets/images/dom-zlote-pola/n3.jpg'), // 4 = 2
        require('@/assets/images/dom-zlote-pola/fot1.jpg'), // 3 = 4
      ]
    }
  }),
  methods: {}
}
</script>